
<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script lang="ts" setup>

const siteStore = useSiteStore()
await siteStore.fetchData()

</script>