import { default as _91_46_46_46route_45path_93OBJaTvhwakMeta } from "/home/vsts/work/1/s/pages/[...route-path].vue?macro=true";
import { default as demov5a2SMcofdMeta } from "/home/vsts/work/1/s/pages/demo.vue?macro=true";
import { default as indexegOWm4p6gtMeta } from "/home/vsts/work/1/s/pages/index.vue?macro=true";
export default [
  {
    name: "routepath",
    path: "/:routepath(.*)*",
    component: () => import("/home/vsts/work/1/s/pages/[...route-path].vue").then(m => m.default || m)
  },
  {
    name: "demo",
    path: "/demo",
    component: () => import("/home/vsts/work/1/s/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/pages/index.vue").then(m => m.default || m)
  }
]